<template>
    <section>
            <div class="row">
                <div class="col-12">
                    <div class="card border-main">
                        <div class="card-header" style="border-bottom: 1px solid rgb(255 123 35)">
                            <h3 class="tour-title-header color-main">{{ tour_type_title }}</h3>
                            <filter-zone :id="'filter-zone'" :tour_type="tour_type" :tour_list="tour_list" :destinations="destinations" :subjects="subjects" @getResults="getResults" ></filter-zone>
                        </div>
                        <!-- /.card-header -->
                            <tour-list  v-if="tour_list.data" :tour_list="tour_list" @changeCurrentShow="changeCurrentShow" @showOrders="showOrders"></tour-list>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination v-if="tour_list.data" :data="tour_list" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="showBook" tabindex="-1" role="dialog" aria-labelledby="showBook=" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" >Danh sách chỗ đặt</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                            <div class="modal-body">
                                <div class="card-header">
                                    <h3 class="tour-title-header">{{ selected_startdate.tour_name }}</h3>
                                    <h5 class="tour-title-header">{{ selected_startdate.startdate | formatDate }}</h5>
                                </div>
                                <div class="card-body table-responsive p-0">
                                    <order-list :selected_startdate="selected_startdate" :orders="order_list"></order-list>
                                </div>
                                <div class="card-footer">
                                    <pagination v-if="order_list.data" :data="order_list" @pagination-change-page="showOrders"></pagination>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
                            </div>
                    </div>
                </div>
            </div>
    </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import { Datetime } from 'vue-datetime';
    import 'vue-datetime/dist/vue-datetime.css'
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/vi';
    import moment from "moment";
    import { Settings } from 'luxon';
    Settings.defaultLocale = 'vi';
    export default {
        props: {
            tour_type : Number
        },
        components: { DatePicker , Datetime },
        data () {
            return {
                current_show : 20,
                current_page : 1 ,
                order_current_page : 1 ,
                destinations : [],
                subjects : [],
                tour_book_list : [],
                tour_list : { data: false , total : 0},
                selected_startdate : {} ,
                order_list : { data: false},
                tour_type_title : '',
                search_form : new Form({
                    from_child: false,
                    page: 1,
                    search: "",
                    search_date: false,
                    search_destinations: [],
                }),
                orders: [],
            }
        },
        methods: {
            // setChangeRoomName(event) {
            //     this.change_room = event.target.value;
            // },
            // getRoomTypes() {
            //     axios.get('api/room/type').then(({ data }) => (this.room_types = data.data));
            // },
            setFilter(search_form) {
                this.search_form = search_form ;
            },
            getResults( page = 1 , filters =  {}) {
                this.$Progress.start();
                if(!page){
                    page = 1;
                }
                this.search_form.page  = page;
                this.search_form.tour_type= this.tour_type;
                if(filters.from_child){
                    this.search_form.search =  filters.search;
                    this.search_form.search_date =  filters.search_date;
                    this.search_form.search_destinations =  filters.search_destinations;
                }
                this.$helpers.getTours(this.search_form).then((response) =>{
                    this.tour_list = response.data;
                }).catch((error)=> {
                    let message = 'Something went wrong. Please try again.' ;
                    if (typeof error.response.data === 'object') {
                        message = error.response.data.message;
                    }
                    Swal.fire("Thất bại !", message, "warning");
                    this.$Progress.fail();
                });
                this.$Progress.finish();
            },
            showOrders (page = 1 , startdate) {
                this.$Progress.start();
                this.selected_startdate = startdate ;
                this.$helpers.getOrders({page : page , startdate_id : startdate.id}).then((response) =>{
                    $('#showBook').modal('show');
                    this.order_list = response.data;
                    this.$Progress.finish();
                });
            },
            changeCurrentShow(show = 20) {
                this.getResults();
            }
        },
        async mounted() {
            this.$Progress.start();
            // console.log('Tour Component mounted.')
            if(this.tour_type === 0 ){
                this.tour_type_title = 'Tour trong nước';
            } else {
                this.tour_type_title = 'Tour nước ngoài';
            }
            this.destinations =  (await this.$helpers.getDestinations(this.tour_type)).map(x=> {
                return {
                    name : x.title,
                    value : x.slug
                }
            });
            this.subjects =  (await this.$helpers.getSubjects(this.tour_type)).map(x=> {
                return {
                    name : x.title,
                    value : x.slug
                }
            });
            this.destinations = [ { name : '--- Tất cả ---', value : ''} , ...this.destinations];
            this.$helpers.getTours({page: 1 , tour_type : this.tour_type}).then((response)  => {
                this.tour_list = response.data;
            }).catch((error)=> {
                let message = 'Something went wrong. Please try again.' ;
                if (typeof error.response.data === 'object') {
                    message = error.response.data.message;
                }
                Swal.fire("Thất bại !", message, "warning");
                this.$Progress.fail();
            });
            this.$Progress.finish();
        },
        updated() {

        },
        async created() {
            // this.$Progress.start();
            //
            // this.$Progress.finish();
        },
        watch : {
            async $route (to, from){
                if(to.path === '/tour-trong-nuoc' || to.path === '/tour-nuoc-ngoai'){
                    if(this.tour_type === 0 ){
                        this.tour_type_title = 'Tour trong nước';
                    } else {
                        this.tour_type_title = 'Tour nước ngoài';
                    }
                    this.destinations =  (await this.$helpers.getDestinations(this.tour_type)).map(x=> {
                        return {
                            name : x.title,
                            value : x.slug
                        }
                    });
                    this.subjects =  (await this.$helpers.getSubjects(this.tour_type)).map(x=> {
                        return {
                            name : x.title,
                            value : x.slug
                        }
                    });
                    this.destinations = [ { name : '--- Tất cả ---', value : ''} , ...this.destinations];
                    this.$helpers.getTours({page: 1 , tour_type : this.tour_type}).then((response)  => {
                        this.tour_list = response.data;
                    });
                }
            }
        }
    }
</script>
